<template>
    <div>
        <portal to="layout-default-header">
            <div class="d-flex flex-row">
                <div class="d-flex flex-column flex-grow-1">
                    <h1 class="pt-3">
                        {{ $t('programs.edit.title') }}
                    </h1>
                </div>

                <div class="d-flex flex-column justify-content-end">
                    <portal-target name="program-edit-actions" />
                </div>
            </div>
        </portal>

        <wait-for-resource :resource="program">
            <side-nav-router-view
                :nav-items="navItems"
                :program="program"
            >
                <template #nav-item="{item}">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>
                            {{ $t(`${item.location.name}.nav`) }}
                        </span>

                        <span
                            v-if="item.count != undefined"
                            class="ml-2 badge badge-pill"
                            :class="item.count > 0 ? 'bg-text text-white' : 'badge-danger'"
                        >
                            {{ item.count }}
                        </span>
                    </div>
                </template>
            </side-nav-router-view>
        </wait-for-resource>
    </div>
</template>

<script>
import {Program} from '@/models/Program';
import SideNavRouterView from '@/components/layouts/SideNavRouterView';

export default {
    name: 'ProgramEditRouterView',
    components: {SideNavRouterView},
    props: {
        /**
         * The program identifier, passed by route.
         */
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            program: new Program({id: this.id}),
        };
    },
    computed: {
        navItems() {
            return [
                {
                    location: this.program.getLocation('edit'),
                },
                {
                    location: this.program.getLocation('edit.chapters'),
                    count: this.program.chapters
                        ? this.program.chapters.size()
                        : this.program.get('chaptersCount', 0),
                },
                {
                    location: this.program.getLocation('edit.clients'),
                    count: this.program.clients
                        ? this.program.clients.size()
                        : this.program.get('postUsersCount', 0),
                },
            ];
        },
    },
    async created() {
        await this.program.fetch();
    },
};
</script>
